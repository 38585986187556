/** @jsxImportSource @emotion/react */
import { VFC } from 'react';
import { mq } from '../../helpers/mediaQueryHelper';
import { COLOR } from '../../styles/color';
import { FONT_WEIGHT } from '../../styles/fontWeight';

type Props = {
  label: string
  callback: () => void
};

export const TextButton: VFC<Props> = (props: Props) => {
  return (
    <button css={ style } onClick={ props.callback }>
      <p css={ labelStyle }>{ props.label }</p>
    </button>
  );
};

const style = mq({
  alignItems: 'center',
  backgroundColor: 'transparent',
  border: 'none',
  display: 'flex',
  flexDirection: 'column',
  height: ['24px', '36px', '56px', '104px', '152px'],
  padding: ['8px', '12px', '16px', '16px', '16px'],
  width: ['160px', '160px', '240px', '320px', '400px'],
  justifyContent: 'center',
  cursor: 'pointer',
  boxSizing: 'content-box',
});

const labelStyle = mq({
  color: COLOR.WHITE,
  fontSize: ['16px', '28px', '48px', '56px', '64px'],
  fontWeight: FONT_WEIGHT.BOLD,
  lineHeight: ['24px', '36px', '56px', '104px', '152px'],
  width: '100%',
});
