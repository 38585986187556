export const COLOR = {
  WHITE: '#ffffff',
  RED: '#FA6B62',
  text: {
    PRIMARY: '#394257',
    SECONDARY: '#8A8A90',
  },
  accent: {
    airQuality: [
      '#00AFEC',
      '#00AFCC',
      '#69BD83',
      '#519768',
      '#315C6E'
    ]
  }
};
