import { ReactNode, useState } from 'react'
import { AuthCompanyContext } from '../contexts/authCompanyContext'
import { ObjectOfficeDevice } from '../types/api/OfficeDevice'
import { AuthCompanyContextType } from '../types/AuthCompanyContextType'
import { CompanyType } from '../types/CompanyType'

type Props = {
  children: ReactNode
}

export const AuthCompanyProvider = (props: Props) => {
  const [officeDevice, setOfficeDevice] = useState<ObjectOfficeDevice | null>(null);

  const signIn = (newOfficeDevice: ObjectOfficeDevice, callback: () => void) => {
    setOfficeDevice(newOfficeDevice);
    localStorage.setItem('officeDeviceId', newOfficeDevice.id);
    localStorage.setItem('signageMacAddress', newOfficeDevice.macAddress);
    callback();
  }

  const signOut = (callback: () => void) => {
    setOfficeDevice(null);
    localStorage.removeItem('officeDeviceId');
    localStorage.removeItem('signageMacAddress');
    callback();
  }

  const value: AuthCompanyContextType = { officeDevice, signIn, signOut }

  return (
    <AuthCompanyContext.Provider value={value}>
      {props.children}
    </AuthCompanyContext.Provider>
  )
}
