/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FunctionComponent, useState } from 'react';
import { mq } from '../helpers/mediaQueryHelper';
import useInterval from '../hooks/useInterval';
import { COLOR } from '../styles/color';
import { FONT_WEIGHT } from '../styles/fontWeight';

export const DigitalClock: FunctionComponent = () => {
  const [date, setDate] = useState(new Date());

  useInterval(() => {
    setDate(new Date());
  }, 1000);

  return (
    <div css={containerStyle}>
      <span css={dateStyle}>{`${date.getFullYear()}/${
        date.getMonth() + 1
      }/${date.getDate()}`}</span>
      <span css={timeStyle}>{`${zeroPadding(date.getHours())}:${zeroPadding(
        date.getMinutes()
      )}`}</span>
    </div>
  );
};

const zeroPadding = (value: number) => {
  return value.toString().padStart(2, '0');
};

const containerStyle = () =>
  css({
    display: 'flex',
    flexDirection: 'column',
  });

const dateStyle = () =>
  mq({
    color: COLOR.WHITE,
    fontSize: ['10px', '14px', '24px', '28px', '32px'],
    fontWeight: FONT_WEIGHT.REGULAR,
    lineHeight: '100%',
  });

const timeStyle = () =>
  mq({
    color: COLOR.WHITE,
    fontSize: ['32px', '40px', '72px', '96px', '120px'],
    fontWeight: FONT_WEIGHT.REGULAR,
    lineHeight: '100%',
  });
