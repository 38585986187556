import Axios from 'axios';
import { getBffUri } from './uriHelper';
import camelcaseKeys from "camelcase-keys";

const baseURL = getBffUri();

export const axiosInstance = Axios.create({
  baseURL,
  headers: {
    "X-API-KEY": process.env.REACT_APP_API_KEY || "",
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
  },
});

axiosInstance.interceptors.response.use(
  (response) => {
    const { data } = response;
    const convertedData = camelcaseKeys(data, { deep: true });
    return { ...response, data: convertedData };
  },
  (error) => {
    return error.response;
  }
);


