/** @jsxImportSource @emotion/react */
import * as React from 'react';
import { mq } from '../helpers/mediaQueryHelper';

type Props = {
  size: string[];
};

function IcnHintCircle(props: Props) {
  return (
    <div css={mq({ width: props.size, height: props.size })}>
      <svg fill="none" viewBox="0 0 24 24">
        <circle cx={12} cy={12} r={12} fill="#E7E7E7" />
        <path
          d="M14.994 9.638a3.002 3.002 0 00-2.757-2.877 2.99 2.99 0 00-2.302.791C9.335 8.116 9 8.882 9 9.71c0 .396.324.72.72.72.395 0 .719-.324.719-.72 0-.42.168-.815.48-1.103.323-.3.755-.443 1.21-.407.78.06 1.403.719 1.427 1.498a1.469 1.469 0 01-.792 1.379 2.866 2.866 0 00-1.486 2.53c0 .395.323.719.72.719.395 0 .719-.324.719-.72 0-.54.275-1.03.73-1.27a2.938 2.938 0 001.547-2.698zM12.117 16.747a.96.96 0 100-1.918.96.96 0 000 1.918z"
          fill="#8A8A90"
        />
      </svg>
    </div>
  );
}

const MemoIcnHintCircle = React.memo(IcnHintCircle);
export default MemoIcnHintCircle;
