/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useState, VFC } from 'react'
import { CSSTransition } from 'react-transition-group'
import { getAirQualityImage } from '../helpers/airQualityHelper'
import { mq } from '../helpers/mediaQueryHelper'
import MemoIcnCloseCircle from '../images/IcnCloseCircle'
import MemoIcnHintCircle from '../images/IcnHintCircle'
import { COLOR } from '../styles/color'
import { Button } from './buttons/Button'

export const AirQualitySignGuide: VFC = () => {
  const legendImgSize = ['16px', '20px', '32px', '56px', '64px']
  const [isOpened, setIsOpend] = useState(false)

  const onClick = () => {
    setIsOpend(!isOpened)
  }

  return (
    <div css={style}>
      <div css={legendStyle}>
        <div css={legendImagesStyle}>
          {(
            [...Array(5)].map((_, i) => {
              return (
                <CSSTransition
                  key={i}
                  in={isOpened}
                  timeout={i * 500}
                  classNames="fade"
                  unmountOnExit
                >{getAirQualityImage(i + 1, legendImgSize, legendImageStyle(i * 500))}</CSSTransition>
              )
            })
          )}
        </div>
        <CSSTransition
          in={isOpened}
          timeout={500}
          classNames="fade"
          unmountOnExit
        >
          <div css={legendLabelsStyle(legendImgSize)}>
            <span css={legendLabelStyle(legendImgSize)}>Good</span>
            <span css={legendLabelStyle(legendImgSize)}>Bad</span>
          </div>
        </CSSTransition>
      </div>
      
      {(
        isOpened ?
          <Button onClick={onClick} style={buttonStyle(legendImgSize)}>
            <MemoIcnCloseCircle size={legendImgSize} isFill={true} />
          </Button>
          :
          <Button onClick={onClick} style={buttonStyle(legendImgSize)}>
            <MemoIcnHintCircle size={legendImgSize} />
          </Button>
      )}
    </div>
  )
}



const style = mq({
  alignItems: 'flex-start',
  justifyContent: 'flex-end',
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: ['34px', '42px','66px', '82px', '98px'],
  gap: ['8px', '8px', '24px', '24px', '24px'],
})

const buttonStyle = (size: string[]) => mq({
  width: size,
  height: size,
  background: 'none',
  border: 'none',
  padding: 0,
  borderRadius: '100%',
})

const legendStyle = mq({
  display: 'flex',
  flexDirection: 'column',
  gap: ['4px', '4px', '8px', '12px', '16px'],
})

const legendImagesStyle = mq({
  display: 'flex',
  flexDirection: 'row',
  gap: ['4px', '4px', '8px', '8px', '8px'],
})

const legendImageStyle = (delay: number) => css({
  transitionDelay: `${delay}ms`,
  '&.fade-enter': {
    opacity: 0,
  },
  '&.fade-enter-active': {
    opacity: 1,
    transition: 'opacity 200ms ease-out',
  },
  '&.fade-exit': {
    opacity: 1,
  },
  '&.fade-exit-active': {
    opacity: 0,
    transition: 'opacity 200ms ease-out',
  },
})

const legendLabelsStyle = (size: string[]) => mq({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  position: 'relative',
  '&:before, &:after': {
    content: '""',
    flexGrow: '1',
    background: `linear-gradient(to right, ${COLOR.accent.airQuality[0]}, ${COLOR.accent.airQuality[4]})`,
    height: ['1px', '2px', '4px', '4px', '4px'],
    position: 'absolute',
    margin: 'auto 0',
    top: 0,
    left: size,
    right: size,
    bottom: 0,
    borderRadius: ['0.5px', '1px', '2px', '2px', '2px'],
  },
  '&.fade-enter': {
    opacity: 0,
  },
  '&.fade-enter-active': {
    opacity: 1,
    transition: 'opacity 200ms ease-out',
  },
  '&.fade-exit': {
    opacity: 1,
  },
  '&.fade-exit-active': {
    opacity: 0,
    transition: 'opacity 200ms ease-out',
  },
})

const legendLabelStyle = (size: string[]) => mq({
  width: size,
  textAlign: 'center',
  fontSize: ['5px', '6px', '10px', '14px', '18px'],
  lineHeight: ['5px', '6px', '10px', '14px', '18px'],
})