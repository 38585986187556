import { ReactNode, useState } from 'react';
import { MenuContext } from '../contexts/menuContext';
import { MenuContextType } from '../types/MenuContextType';

type Props = {
  children: ReactNode;
};

export const MenuProvider = (props: Props) => {
  const [displayFlag, setDisplayFlag] = useState<boolean>(false);
  const useSetDisplayFlag = (displayFlag: boolean) => {
    setDisplayFlag(displayFlag)
  }
  const value: MenuContextType = {
    displayFlag,
    useSetDisplayFlag,
  }

  return (
    <MenuContext.Provider value={value}>
      {props.children}
    </MenuContext.Provider>
  )
}