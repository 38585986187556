/** @jsxImportSource @emotion/react */
import * as React from 'react';
import { mq } from '../helpers/mediaQueryHelper';

type Props = {
  size: string[];
};

function IcnFourDots(props: Props) {
  return (
    <div css={mq({ width: props.size, height: props.size })}>
      <svg fill="none" viewBox="0 0 36 36">
        <path
          d="M10 5A5 5 0 110 5a5 5 0 0110 0zM10 31a5 5 0 11-10 0 5 5 0 0110 0zM36 5a5 5 0 11-10 0 5 5 0 0110 0zM36 31a5 5 0 11-10 0 5 5 0 0110 0z"
          fill="#fff"
        />
      </svg>
    </div>
  );
}

const MemoIcnFourDots = React.memo(IcnFourDots);
export default MemoIcnFourDots;
