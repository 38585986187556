/** @jsxImportSource @emotion/react */
import { VFC } from 'react';
import { mq } from '../../helpers/mediaQueryHelper';
import MemoIcnClose from '../../images/IcnClose';
import { BORDER_RADIUS } from '../../styles/borderRadius';

type Props = {
  onClick: () => void;
};

export const CloseButton: VFC<Props> = (props: Props) => {
  return (
    <button onClick={props.onClick} css={style}>
      <MemoIcnClose size={['16px', '18px', '24px', '28px', '32px']} />
    </button>
  );
};

const style = mq({
  alignItems: 'center',
  backgroundColor: 'transparent',
  borderColor: 'transparent',
  borderRadius: BORDER_RADIUS.PRIMARY,
  display: 'flex',
  height: ['32px', '40px', '72px', '92px', '112px'],
  justifyContent: 'center',
  width: ['32px', '40px', '72px', '92px', '112px'],
  cursor: 'pointer',
});
