import './App.css';
import { Providers } from './components/Providers';
import { RouterConfig } from './components/RouterConfig';
import 'react-toastify/dist/ReactToastify.css';
import { Maintenance } from './pages/Maintenance';

function App() {
  return (
    <>
      <Providers>
        <RouterConfig />
      </Providers>
      {/** <Maintenance /> */}
    </>
  );
}

export default App;
