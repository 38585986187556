import { Theme, ThemeProvider } from '@emotion/react';
import { ReactNode, useState } from 'react';
import { CustomThemeContext } from '../contexts/customThemeContext';
import { Theme00 } from '../styles/theme';
import { CustomThemeContextType } from '../types/CustomThemeContextType';

type Props = {
  children: ReactNode;
};

export const CustomThemeProvider = (props: Props) => {
  const [theme, setTheme] = useState<Partial<Theme>>(Theme00);
  const useSetTheme = (theme: Partial<Theme>) => {
    setTheme(theme)
  }
  const value: CustomThemeContextType = {
    theme,
    useSetTheme,
  }

  return (
    <CustomThemeContext.Provider value={value}>
      <ThemeProvider theme={theme}>
        {props.children}
      </ThemeProvider>
    </CustomThemeContext.Provider>
  )
}