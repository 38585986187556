/** @jsxImportSource @emotion/react */
import * as React from 'react';
import { mq } from '../helpers/mediaQueryHelper';

type Props = {
  size: string[]
  isFill?: boolean
};

function IcnCloseCircle(props: Props) {
  return (
    <div css={mq({ width: props.size, height: props.size })}>
      <svg fill="none" viewBox="0 0 32 32">
        <circle cx={16} cy={16} r={15} stroke={ props.isFill ? "#8A8A90" : "#fff"} strokeWidth={props.isFill ? 0 : 2} fill={props.isFill ? '#E7E7E7' : 'transparent'} />
        <path
          d="M20.326 10.495L16 14.82l-4.326-4.326a.833.833 0 00-1.18 0 .833.833 0 000 1.18L14.82 16l-4.326 4.324a.833.833 0 000 1.18.833.833 0 001.18 0L16 17.18l4.326 4.326a.835.835 0 001.18-1.18L17.18 16l4.326-4.325a.833.833 0 000-1.18.837.837 0 00-1.18 0z"
          fill={ props.isFill ? "#8A8A90" : "#fff"}
        />
      </svg>
    </div>
  );
}

const MemoIcnCloseCircle = React.memo(IcnCloseCircle);
export default MemoIcnCloseCircle;
