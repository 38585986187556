import { ReactNode, VFC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthCompanyContext } from '../contexts/authCompanyContext';

type Props = {
  component: ReactNode;
  redirect: string;
};

export const RouteAuthGuard: VFC<Props> = (props: Props) => {
  // const authCompany = useAuthCompanyContext().officeDevice;
  const officeDeviceId = localStorage.getItem('officeDeviceId');
  

  if (!officeDeviceId) {
    return (
      <Navigate
        to={props.redirect}
        state={{ from: useLocation() }}
        replace={false}
      />
    );
  }

  return <>{props.component}</>;
};
