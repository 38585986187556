/** @jsxImportSource @emotion/react */
import * as React from 'react';
import { mq } from '../helpers/mediaQueryHelper';

type Props = {
  size: string[];
};

function IcnArrowLeft(props: Props) {
  return (
    <div css={mq({ width: props.size, height: props.size })}>
      <svg fill="none" viewBox="0 0 32 32">
        <g clipPath="url(#prefix__clip0_249_3290)">
          <path
            d="M.419 14.774l8.96-10.88c.742-.83 1.93-.91 2.71-.27.78.638.91 1.93.27 2.71l-6.38 7.74h24.1a1.92 1.92 0 110 3.84H5.98l6.38 7.74c.64.78.495 2.053-.27 2.71-.788.677-2.071.51-2.71-.27L.42 17.214c-.583-.82-.533-1.647 0-2.44z"
            fill="#fff"
          />
        </g>
        <defs>
          <clipPath id="prefix__clip0_249_3290">
            <path fill="#fff" d="M0 0h32v32H0z" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

const MemoIcnArrowLeft = React.memo(IcnArrowLeft);
export default MemoIcnArrowLeft;
