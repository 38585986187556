/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { VFC } from 'react'
import { getAirQualityImage, getAirQualityLevel, getAirQualityMessage } from '../helpers/airQualityHelper'
import { mq } from '../helpers/mediaQueryHelper'
import { COLOR } from '../styles/color'
import { FONT_WEIGHT } from '../styles/fontWeight'
import { OfficeDeviceLogListResponse } from '../types/api/ExApp'
import { ObjectOfficeDevice } from '../types/api/OfficeDevice'
import { DeviceDataType } from '../types/DeviceDataType'
import { AirQualitySignGuide } from './AirQualitySignGuide'

type Props = {
  officeDeviceLogList: OfficeDeviceLogListResponse | null,
}

export const AirQualitySign: VFC<Props> = (props: Props) => {
  const airQualityLevel = getAirQualityLevel(
    props.officeDeviceLogList?.latestLog?.co2Value || 0,
    props.officeDeviceLogList?.latestLog?.tvocValue || 0,
    props.officeDeviceLogList?.latestLog?.pm25Value || 0,
  )
  return (
    <div css={style}>
      <p
        css={messageStyle}
      >{ getAirQualityMessage(airQualityLevel) }</p>
      { getAirQualityImage(airQualityLevel) }
      <AirQualitySignGuide />
    </div>
  )
}

const style = mq({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  width: '100%',
  paddingTop: ['16px', '32px', '56px', '88px', '120px']
})

const messageStyle = mq({
  color: COLOR.text.PRIMARY,
  fontSize: ['10px', '12px', '24px', '28px', '32px'],
  fontWeight: FONT_WEIGHT.MEDIUM,
  lineHeight: '150%',
  margin: 0,
  textAlign: 'center',
  whiteSpace: 'pre-wrap',
})
