/** @jsxImportSource @emotion/react */
import * as React from 'react';
import { mq } from '../helpers/mediaQueryHelper';

type Props = {
  size: string[];
};

function IcnClose(props: Props) {
  return (
    <div css={mq({ width: props.size, height: props.size })}>
      <svg fill="none" viewBox="0 0 32 32">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.445.763A2.604 2.604 0 00.763 4.445L12.318 16 .763 27.555a2.604 2.604 0 003.682 3.682L16 19.682l11.555 11.555a2.604 2.604 0 003.682-3.682L19.682 16 31.237 4.445A2.604 2.604 0 0027.555.763L16 12.318 4.445.763z"
          fill="#fff"
        />
      </svg>
    </div>
  );
}

const MemoIcnClose = React.memo(IcnClose);
export default MemoIcnClose;
