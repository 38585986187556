import { VFC, ReactNode } from 'react'
import { AuthCompanyProvider } from '../providers'
import { CustomThemeProvider } from '../providers/CustomThemeProvider'
import { MenuProvider } from '../providers/MenuProvider'

type Props = {
  children: ReactNode
}

export const Providers: VFC<Props> = (props) => {
  return (
    <>
      <CustomThemeProvider>
        <AuthCompanyProvider>
          <MenuProvider>{props.children}</MenuProvider>
        </AuthCompanyProvider>
      </CustomThemeProvider>
    </>
  )
}
