/** @jsxImportSource @emotion/react */
import * as React from 'react';
import { mq } from '../helpers/mediaQueryHelper';

type Props = {
  size: string[]
}

function IcnPin(props: Props) {
  return (
    <div css={mq({ width: props.size, height: props.size, display: 'flex' })}>
      <svg fill="none" viewBox="0 0 32 32">
        <g clipPath="url(#prefix__clip0_247_3281)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.506 4.433c-5.244 5.243-5.244 13.745 0 18.988l8.784 8.785a1.004 1.004 0 001.42 0l8.784-8.785c5.244-5.243 5.244-13.745 0-18.988-5.243-5.244-13.745-5.244-18.988 0zm3.622 9.143a6.024 6.024 0 1112.047 0 6.024 6.024 0 01-12.047 0z"
            fill="#fff"
          />
        </g>
        <defs>
          <clipPath id="prefix__clip0_247_3281">
            <path fill="#fff" transform="translate(0 .5)" d="M0 0h32v32H0z" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

const MemoIcnPin = React.memo(IcnPin);
export default MemoIcnPin;
