import { useEffect } from 'react';

type Callback = {
  (): void;
};

const useInterval = (callback: Callback, delay?: number) => {
  useEffect(() => {
    const interval = setInterval(() => callback(), delay || 1000);
    return () => clearInterval(interval);
  }, [callback, delay]);
};

export default useInterval;
