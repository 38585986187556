/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { VFC } from 'react';
import { mq } from '../helpers/mediaQueryHelper';
import MemoIcnPin from '../images/IcnPin';
import { COLOR } from '../styles/color';
import { FONT_WEIGHT } from '../styles/fontWeight';

type Props = {
  label: string;
};

export const Location: VFC<Props> = (props: Props) => {
  return (
    <div css={style}>
      <MemoIcnPin size={['16px', '20px', '24px', '28px', '32px']} />
      <span css={labelStyle}>{props.label}</span>
    </div>
  );
};

const style = mq({
  alignItems: 'center',
  backgroundColor: 'transparent',
  display: 'flex',
  flexDirection: 'row',
  gap: ['8px', '16px', '18px', '20px', '24px'],
  height: ['32px', '40px', '72px', '92px', '112px'],
  padding: ['0px 8px', '0px 16px', '0px 32px', '0px 32px', '0px 40px'],
});

const labelStyle = mq({
  color: COLOR.WHITE,
  fontSize: ['10px', '14px', '18px', '22px', '24px'],
  fontWeight: FONT_WEIGHT.BOLD,
});
