/** @jsxImportSource @emotion/react */
import { DynamicStyle } from 'facepaint';
import { ReactNode, VFC } from 'react';

type Props = {
  children: ReactNode
  onClick: () => void
  style: DynamicStyle[]
};

export const Button: VFC<Props> = (props: Props) => {
  return (
    <button onClick={props.onClick} css={props.style}>
      {props.children}
    </button>
  );
};

