import { CamelCasedPropertiesDeep } from "type-fest";
import { components, operations } from "../../schema";

export const OfficeDeviceVerificationURI = "/ex-app/office-device/verification";
export const OfficeDeviceLogListURI = (id: string) => `/ex-app/office-device/${id}/log/list`;

export type OfficeDeviceVerificationParams =
components["schemas"]["RequestBody_VerifyOfficeDevice"];

export type OfficeDeviceLogListResponse = CamelCasedPropertiesDeep<
components["schemas"]["Response_OfficeDeviceLogList"]
>;

export type OfficeDeviceVerificationResponse = CamelCasedPropertiesDeep<
components["schemas"]["Response_OfficeDevice"]
>;

