import { Theme00, Theme01, Theme02, Theme03, Theme04, Theme05 } from "../styles/theme"

export const getThemeFromAirQualityLevel = (airQualityLevel: number) => {
  switch(airQualityLevel) {
    case 1:
      return Theme01
    case 2:
      return Theme02
    case 3:
      return Theme03
    case 4:
      return Theme04
    case 5:
      return Theme05
    default:
      return Theme00
  }
}
