import facepaint from 'facepaint'

const breakpoints = [667, 1024, 1365, 1728]

export const mq = facepaint(
  breakpoints.map(bp => `@media (min-width: ${bp}px)`)
)

// const breakpoints = [1132, 1365, 1728]
//         width < 1132 | iPhone SE, iPhone 8, iPhone 8 Plus, iPad mini, iPad, iPad Pro 10.5, 800
// 1132 <= width < 1365
// 1365 <= width < 1728 | iPad Pro 12.9, 1366
// 1728 <= width        | 1916

// const breakpoints = [736, 1132, 1365, 1728]
//         width < 736  | iPhone SE, iPhone 8
// 736  <= width < 1132 | iPhone 8 Plus, iPad mini, iPad, iPad Pro 10.5, 800
// 1132 <= width < 1365 | 
// 1365 <= width < 1728 | iPad Pro 12.9, 1366
// 1728 <= width        | 1916

// const breakpoints = [667, 1024, 1365, 1728]
//         width < 667  | iPhone SE
// 667  <= width < 1024 | iPhone 8, iPhone 8 Plus, 800
// 1024 <= width < 1365 | iPad mini, iPad, iPad Pro 10.5
// 1365 <= width < 1728 | iPad Pro 12.9, 1366
// 1728 <= width        | 1916