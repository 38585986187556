export const Theme00 = {
  colors: {
    primary: '#00A0E9',
  },
};

export const Theme01 = {
  colors: {
    primary: '#00AFEC',
  },
};

export const Theme02 = {
  colors: {
    primary: '#00AFCC',
  },
};

export const Theme03 = {
  colors: {
    primary: '#69BD83',
  },
};

export const Theme04 = {
  colors: {
    primary: '#519768',
  },
};

export const Theme05 = {
  colors: {
    primary: '#315C6E',
  },
};
