/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useState, VFC } from 'react';
import { toast } from 'react-toastify';
import { AirQualitySign } from '../components/AirQualitySign';
import { FourDotsButton } from '../components/buttons/FourDotsButton';
import { SquareButton } from '../components/buttons/SquareButton';
import { Card } from '../components/Card';
import { DigitalClock } from '../components/DigitalClock';
import { Location } from '../components/Location';
import { Menu } from '../components/Menu';
import { useAuthCompanyContext } from '../contexts/authCompanyContext';
import { useCustomThemeContext } from '../contexts/customThemeContext';
import { useMenuContext } from '../contexts/menuContext';
import { getAirQualityLevel } from '../helpers/airQualityHelper';
import { axiosInstance } from '../helpers/apiHelper';
import { openInNewTab } from '../helpers/linkHelper';
import { mq } from '../helpers/mediaQueryHelper';
import { getThemeFromAirQualityLevel } from '../helpers/themeHelper';
import { getDashboardUri } from '../helpers/uriHelper';
import { useFetchWeatherData } from '../hooks/useFetchWeatherData';
import { BORDER_RADIUS } from '../styles/borderRadius';
import { COLOR } from '../styles/color';
import { Theme00 } from '../styles/theme';
import { OfficeDeviceLogListResponse, OfficeDeviceLogListURI } from '../types/api/ExApp';
import { ObjectOfficeDevice } from '../types/api/OfficeDevice';
import { AuthCompanyContextType } from '../types/AuthCompanyContextType';
import { CustomThemeContextType } from '../types/CustomThemeContextType';
import { MenuContextType } from '../types/MenuContextType';

export const Home: VFC = () => {
  const menuContext: MenuContextType = useMenuContext()
  const customThemeContext: CustomThemeContextType = useCustomThemeContext()
  const [officeDeviceLogList, setOfficeDeviceLogList] = useState<OfficeDeviceLogListResponse | null>(null)
  const [airQualityLevel, setAirQualityLevel] = useState<number | null>(null)
  const authCompanyContext: AuthCompanyContextType = useAuthCompanyContext()
  const INTERVAL = 5 * 60 * 1000
  const officeDeviceId = localStorage.getItem('officeDeviceId');
  const TVOC_ALLOW_LISTS = process.env.REACT_APP_ALLOW_TVOC_LIST?.split(',');
  const signInMacAddress = localStorage.getItem('signageMacAddress');

  const fetchOfficeDeviceLogList = async (id: string) => {
    const res = await axiosInstance.get<OfficeDeviceLogListResponse>(OfficeDeviceLogListURI(id), {});
  
    if (res.status === 200) {
      const officeDeviceLogList = res.data as OfficeDeviceLogListResponse;
      setOfficeDeviceLogList(officeDeviceLogList);
    } else {
      toast.error("認証エラー");
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (officeDeviceId) {
        fetchOfficeDeviceLogList(officeDeviceId);
      }
    }, INTERVAL)

    if (officeDeviceId) {
      fetchOfficeDeviceLogList(officeDeviceId);
    }

    return () => clearInterval(intervalId)
  }, [officeDeviceId]);

  useEffect(() => {
    const newTheme = getThemeFromAirQualityLevel(airQualityLevel || 0)
    customThemeContext.useSetTheme(newTheme)
  }, [airQualityLevel])

  return (
    <div css={containerStyle(customThemeContext.theme.colors?.primary || Theme00.colors.primary)}>
      <div css={leftColumnStyle}>
        <div css={headerStyle}>
          <FourDotsButton onClick={() => {
            menuContext.useSetDisplayFlag(true)
          }}/>
        </div>
        <div css={bodyStyle}>
          <DigitalClock />
          <div
            css={mq({
              display: 'flex',
              flexDirection: 'row',
              gap: ['4px', '4px', '8px', '12px', '16px'],
              marginTop: ['16px', '16px', '32px', '48px', '64px'],
            })}
          >
            <div
              css={mq({
                display: 'flex',
                flexDirection: 'column',
                gap: ['4px', '4px', '8px', '12px', '16px'],
              })}
            >
              <SquareButton label="ダッシュボードへ" callback={() => { openInNewTab(getDashboardUri() || '/') }} />
              <Card
                id="RH"
                value={officeDeviceLogList?.latestLog?.humidityValue || 0}
                size="SM"
                shape="SQUARE"
              />
            </div>
            <Card
              id="CO2"
              value={officeDeviceLogList?.latestLog?.co2Value || 0}
              size="LG"
              shape="SQUARE"
            />
            <div
              css={mq({
                display: 'flex',
                flexDirection: 'column',
                gap: ['4px', '4px', '8px', '12px', '16px'],
              })}
            >
              <div css={mq({
                display: 'flex',
                flexDirection: 'row',
                gap: ['4px', '4px', '8px', '12px', '16px'],
              })}>
                <Card
                  id="TEMP"
                  value={officeDeviceLogList?.latestLog?.temperatureValue || 0}
                  size="SM"
                  shape="SQUARE"
                />
                <Card
                  id="PM25"
                  value={officeDeviceLogList?.latestLog?.pm25Value || 0}
                  size="SM"
                  shape="RECTANGLE"
                />
              </div>
              {
                signInMacAddress &&
                TVOC_ALLOW_LISTS &&
                TVOC_ALLOW_LISTS.includes(signInMacAddress) &&
                <Card
                  id="TVOC"
                  value={officeDeviceLogList?.latestLog?.tvocValue || 0}
                  size="SM"
                  shape="RECTANGLE"
                />
              }             
            </div>
          </div>
        </div>
        <div css={footerStyle}>
          <Location label={`${authCompanyContext.officeDevice?.office.name}, ${authCompanyContext.officeDevice?.placeName}`} />
        </div>
      </div>
      <div css={rightColumnStyle}>
        <AirQualitySign officeDeviceLogList={officeDeviceLogList} />
      </div>
      <Menu />
    </div>
  )
}

const containerStyle = (backgroundColor: string) =>
  mq({
    backgroundColor: backgroundColor,
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    width: '100%',
    justifyContent: 'space-between',
    padding: ['8px', '12px', '16px', '16px', '16px'],
    boxSizing: 'border-box',
  });

const leftColumnStyle = css({
  display: 'flex',
  flexDirection: 'column',
});

const rightColumnStyle = mq({
  alignItems: 'center',
  backgroundColor: COLOR.WHITE,
  borderRadius: BORDER_RADIUS.PRIMARY,
  display: 'flex',
  flexDirection: 'column',
  maxWidth: ['160px', '220px', '400px', '480px', '560px'],
  padding: ['8px', '8px', '24px', '28px', '32px'],
  flex: 1,
  boxSizing: 'border-box',
});

const headerStyle = css({});

const bodyStyle = mq({
  padding: ['24px 8px', '32px 16px', '64px 24px', '64px 32px', '64px 40px'],
  flex: 1,
});

const footerStyle = css({});
